// src/components/WorkspaceDropdown.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Input,
  VStack,
  Text,
  Avatar,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Portal } from 'react-portal';
import { useAuth } from '../AuthContext'; // Import useAuth from AuthContext

const WorkspaceDropdown = () => {
  const { currentUser, workspaces, currentWorkspace, setWorkspace } = useAuth();
  const [filteredWorkspaces, setFilteredWorkspaces] = useState(workspaces || []);
  const navigate = useNavigate();

  useEffect(() => {
    // Update filteredWorkspaces when workspaces change
    setFilteredWorkspaces(workspaces);
  }, [workspaces]);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    const filtered = workspaces.filter((workspace) =>
      workspace.name.toLowerCase().includes(searchValue)
    );
    setFilteredWorkspaces(filtered);
  };

  const handleWorkspaceSelect = (workspace) => {
    setWorkspace(workspace);
    navigate(`/dashboard/${workspace.id}/overview`);
    // Optionally, you can reload data here if needed
  };

  return (
    <Box position="relative" zIndex="1" width="100%">
      <Menu>
        <MenuButton as={Button} width="100%" rightIcon={<ChevronDownIcon />} textAlign="left">
          <Box isTruncated>{currentWorkspace ? currentWorkspace.name : 'Select Workspace'}</Box>
        </MenuButton>
        <Portal>
          <MenuList maxWidth="400px" bg="white" zIndex="1000">
            <Box px="4" py="2">
              <Input placeholder="Search workspace" onChange={handleSearchChange} />
            </Box>
            {filteredWorkspaces.map((workspace) => (
              <MenuItem key={workspace.id} onClick={() => handleWorkspaceSelect(workspace)}>
                <HStack>
                  <Avatar size="sm" name={workspace.name.charAt(0).toUpperCase()} />
                  <VStack align="start" spacing="0">
                    <Text>{workspace.name}</Text>
                    <Text fontSize="sm" color="gray.500">
                      Role:{' '}
                      {workspace.members.find((member) => member.userId === currentUser.uid)?.role ||
                        'Unknown'}
                    </Text>
                  </VStack>
                </HStack>
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};

export default WorkspaceDropdown;
