import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Text,
  Flex,
  Spacer,
  Input,
  VStack,
  Icon,
  Image,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  FaPlay,
  FaPlus,
  FaArrowRight,
  FaPhone,
  FaCog,
  FaComments,
  FaPhoneSlash,
  FaBrain,
  FaEnvelope,
} from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import ghlImage from '../../../../../assets/ghl.png';
import makeImage from '../../../../../assets/make.png';

const NodeSidebar = ({ isOpen, onClose, addNode, viewportCenter }) => {
  const sidebarRef = useRef();
  const [searchTerm, setSearchTerm] = useState('');
  const [hoveredNode, setHoveredNode] = useState(null);

  const handleAddNode = (type, label) => {
    let nodeData = { label };

    if (type === 'settingsNode') {
      nodeData = {
        ...nodeData,
        voiceProvider: '11labs',
        voice: 'sarah',
        fillerInjection: false,
        hipaaEnabled: false,
        enableRecording: true,
        backgroundSound: 'office',
        gptModel: 'gpt-4o-mini',
        agentInstructions: 'You are a helpful assistant',
      };
    }

    const position = {
      x: viewportCenter.x,
      y: viewportCenter.y,
    };

    // Log the position values to the console
    console.log('Viewport Center Position:', position);

    const newNode = {
      id: uuidv4(),
      type: type,
      data: nodeData,
      position: position,
    };
    addNode(newNode);
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const nodes = [
    { type: 'startNode', label: 'Greeting', icon: FaPlay, color: '#5eff7e' },
    { type: 'transferNode', label: 'Transfer', icon: FaPhone, color: '#54ebaf' },
    { type: 'settingsNode', label: 'Settings', icon: FaCog, color: '#787878' },
    { type: 'conversationNode', label: 'Conversation', icon: FaComments, color: '#f07b0e' },
    { type: 'endNode', label: 'End Call', icon: FaPhoneSlash, color: '#db420f' },
    { type: 'knowledgeBaseNode', label: 'Knowledge Base', icon: FaBrain, color: '#5eccff' },
    { type: 'messagesNode', label: 'Messages', icon: FaEnvelope, color: '#6B46C1' },
    { type: 'ghlNode', label: 'Go High Level', icon: ghlImage, color: '#1A202C' },
    { type: 'makeNode', label: 'Make.com', icon: makeImage, color: '#1A202C' },
  ];

  return (
    <Box
      ref={sidebarRef}
      position="fixed"
      right="0"
      width="500px"
      height="calc(100vh - 81px)"
      backgroundColor="white"
      p={4}
      zIndex={10}
      borderLeft="1px"
      borderLeftColor="gray.200"
      overflowY="auto"
    >
      <Flex alignItems="center">
        <Icon as={FaPlus} w={6} h={6} color="gray.500" />
        <Text fontSize="xl" color="gray.500" ml={2}>
          Add Node
        </Text>
        <Spacer />
        <IconButton icon={<CloseIcon />} onClick={onClose} />
      </Flex>
      <Box mt={4}>
        <Input
          placeholder="Search nodes..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          mb={4}
          border="2px solid #CBD5E0"
        />
        <VStack spacing={1}>
          {nodes
            .filter((node) =>
              node.label.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((node) => (
              <Flex
                key={node.type}
                align="center"
                p={3}
                w="100%"
                bg="white"
                borderRadius="10px"
                cursor="pointer"
                border="2px solid transparent"
                onClick={() => handleAddNode(node.type, node.label)}
                onMouseEnter={() => setHoveredNode(node.type)}
                onMouseLeave={() => setHoveredNode(null)}
                _hover={{ bg: '#f2f2f2' }}
                position="relative"
                transition="all 0.3s ease"
              >
                {typeof node.icon === 'string' ? (
                  <Image src={node.icon} w={6} h={6} />
                ) : (
                  <Icon as={node.icon} w={6} h={6} color={node.color} />
                )}
                <Text ml={4}>{node.label}</Text>
                <Box
                  as="span"
                  position="absolute"
                  right="10px"
                  display="flex"
                  alignItems="center"
                  opacity={hoveredNode === node.type ? 1 : 0}
                  transition="opacity 0.3s ease"
                >
                  <Icon as={FaArrowRight} w={4} h={4} color="gray.500" />
                </Box>
              </Flex>
            ))}
        </VStack>
      </Box>
    </Box>
  );
};

export default NodeSidebar;
