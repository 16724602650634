import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Text,
  Flex,
  Spacer,
  Textarea,
  FormLabel,
  Image,
  Input,
  Select,
  Button,
} from '@chakra-ui/react';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { useNodeContext } from '../NodeContext';
import ghlImage from '../../../../../assets/ghl.png';

const GHLSidebar = ({ onClose }) => {
  const {
    selectedNode,
    setSelectedNode,
    nodes,
    setNodes,
    saveGraphData,
    edges,
    deleteNode,
  } = useNodeContext();

  // State variables for the inputs
  const [ghlUrl, setGhlUrl] = useState('');
  const [functionName, setFunctionName] = useState('');
  const [functionDescription, setFunctionDescription] = useState('');
  const [variables, setVariables] = useState([]);

  useEffect(() => {
    if (selectedNode && selectedNode.type === 'ghlNode') {
      setGhlUrl(selectedNode.data.ghlUrl || '');
      setFunctionName(selectedNode.data.functionName || '');
      setFunctionDescription(selectedNode.data.functionDescription || '');
      setVariables(selectedNode.data.variables || []);
    }
  }, [selectedNode]);

  const updateNodeData = (field, value) => {
    const updatedNodes = nodes.map((node) =>
      node.id === selectedNode.id
        ? { ...node, data: { ...node.data, [field]: value } }
        : node
    );
    setNodes(updatedNodes);
    setSelectedNode({
      ...selectedNode,
      data: { ...selectedNode.data, [field]: value },
    });
    saveGraphData(updatedNodes, edges);
  };

  const handleGhlUrlChange = (e) => {
    const updatedGhlUrl = e.target.value;
    setGhlUrl(updatedGhlUrl);
    updateNodeData('ghlUrl', updatedGhlUrl);
  };

  const handleFunctionNameChange = (e) => {
    const updatedFunctionName = e.target.value;
    setFunctionName(updatedFunctionName);
    updateNodeData('functionName', updatedFunctionName);
  };

  const handleFunctionDescriptionChange = (e) => {
    const updatedFunctionDescription = e.target.value;
    setFunctionDescription(updatedFunctionDescription);
    updateNodeData('functionDescription', updatedFunctionDescription);
  };

  // Handlers for variables
  const handleAddVariable = () => {
    const newVariable = { name: '', description: '', type: 'string' };
    const updatedVariables = [...variables, newVariable];
    setVariables(updatedVariables);
    updateNodeData('variables', updatedVariables);
  };

  const handleRemoveVariable = (index) => {
    const updatedVariables = variables.filter((_, i) => i !== index);
    setVariables(updatedVariables);
    updateNodeData('variables', updatedVariables);
  };

  const handleVariableChange = (index, field, value) => {
    const updatedVariables = variables.map((variable, i) =>
      i === index ? { ...variable, [field]: value } : variable
    );
    setVariables(updatedVariables);
    updateNodeData('variables', updatedVariables);
  };

  const handleClose = () => {
    setSelectedNode(null);
    onClose();
  };

  const handleDelete = () => {
    deleteNode(selectedNode.id);
    handleClose();
  };

  return (
    <Box
      position="absolute"
      top="0"
      right="0"
      width="500px"
      height="100%"
      backgroundColor="white"
      zIndex={10}
      borderLeft="1px"
      borderLeftColor="gray.200"
      display="flex"
      flexDirection="column"
    >
      {selectedNode && selectedNode.type === 'ghlNode' && (
        <>
          {/* Header */}
          <Box p={4}>
            <Flex alignItems="center">
              <Image src={ghlImage} w={6} h={6} />
              <Text fontSize="xl" color="gray.500" ml={2}>
                GHL NODE
              </Text>
              <Spacer />
              <IconButton
                mr={4}
                icon={<CloseIcon />}
                onClick={handleClose}
                aria-label="Close"
              />
              <IconButton
                icon={<DeleteIcon />}
                colorScheme="red"
                onClick={handleDelete}
                aria-label="Delete"
              />
            </Flex>
          </Box>
          {/* Scrollable Content */}
          <Box
            flex="1"
            overflowY="auto"
            px={4}
            pb={4}
            sx={{
              '::-webkit-scrollbar': {
                width: '0px',
                background: 'transparent',
              },
              '-ms-overflow-style': 'none',
              'scrollbar-width': 'none',
            }}
          >
            <Box mt={4}>
              <FormLabel>GHL URL</FormLabel>
              <Input
                placeholder="Enter the GHL URL."
                value={ghlUrl}
                onChange={handleGhlUrlChange}
              />
            </Box>
            <Box mt={4}>
              <FormLabel>GHL Function Name</FormLabel>
              <Input
                placeholder="Enter the GHL Function Name."
                value={functionName}
                onChange={handleFunctionNameChange}
              />
            </Box>
            <Box mt={4}>
              <FormLabel>GHL Function Description</FormLabel>
              <Textarea
                placeholder="Enter the GHL Function Description."
                value={functionDescription}
                onChange={handleFunctionDescriptionChange}
              />
            </Box>
            <Box mt={4}>
              <FormLabel>Variables</FormLabel>
              {variables.map((variable, index) => (
                <Box
                  key={index}
                  mb={4}
                  p={2}
                  border="1px solid #ccc"
                  borderRadius="md"
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text fontWeight="bold">Variable {index + 1}</Text>
                    <IconButton
                      icon={<DeleteIcon />}
                      colorScheme="red"
                      size="sm"
                      onClick={() => handleRemoveVariable(index)}
                      aria-label="Remove Variable"
                    />
                  </Flex>
                  <FormLabel mt={2}>Name</FormLabel>
                  <Input
                    value={variable.name}
                    onChange={(e) =>
                      handleVariableChange(index, 'name', e.target.value)
                    }
                    placeholder="Enter variable name"
                  />
                  <FormLabel mt={2}>Description</FormLabel>
                  <Input
                    value={variable.description}
                    onChange={(e) =>
                      handleVariableChange(index, 'description', e.target.value)
                    }
                    placeholder="Enter variable description"
                  />
                  <FormLabel mt={2}>Type</FormLabel>
                  <Select
                    value={variable.type}
                    onChange={(e) =>
                      handleVariableChange(index, 'type', e.target.value)
                    }
                  >
                    <option value="string">String</option>
                    <option value="integer">Integer</option>
                    <option value="array">Array</option>
                    <option value="boolean">Boolean</option>
                    <option value="object">Object</option>
                  </Select>
                </Box>
              ))}
              <Button mt={2} onClick={handleAddVariable}>
                Add Variable
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default GHLSidebar;
