import React, { useState, useRef } from "react";
import {
  Flex,
  IconButton,
  Box,
  Tooltip,
  Input,
  HStack,
} from "@chakra-ui/react";
import ReactFlow, {
  Controls,
  Background,
  useReactFlow,
  ReactFlowProvider,
} from "reactflow";
import "reactflow/dist/style.css";
import { useNavigate, useParams } from "react-router-dom";
import { NodeProvider, useNodeContext } from "./NodeContext";
import {
  FaPlus,
  FaEdit,
  FaEye,
  FaFlask,
  FaEnvelopeOpenText,
  FaRegUserCircle,
} from "react-icons/fa";

// Global Sidebar
import NodeSidebar from "./sidebar/NodeSidebar";
import TestAgentSidebar from "./sidebar/TestAgentSidebar";

// Sidebars
import StartSidebar from "./sidebar/StartSidebar";
import TransferSidebar from "./sidebar/TransferSidebar";
import SettingsSidebar from "./sidebar/SettingsSidebar";
import ConversationSidebar from "./sidebar/ConversationSidebar";
import EndSidebar from "./sidebar/EndSidebar";
import KnowledgeBaseSidebar from "./sidebar/KnowledgeBaseSidebar";
import MessagesSidebar from "./sidebar/MessagesSidebar";
import MakeSidebar from "./sidebar/MakeSidebar"; // Added
import GHLSidebar from "./sidebar/GHLSidebar"; // Added

// Nodes
import StartNode from "./nodes/StartNode";
import TransferNode from "./nodes/TransferNode";
import SettingsNode from "./nodes/SettingsNode";
import ConversationNode from "./nodes/ConversationNode";
import EndNode from "./nodes/EndNode";
import KnowledgeBaseNode from "./nodes/KnowledgeBaseNode";
import MessagesNode from "./nodes/MessagesNode";
import MakeNode from "./nodes/MakeNode"; // Added
import GHLNode from "./nodes/GHLNode"; // Added

import { ChevronLeftIcon } from "@chakra-ui/icons";

const nodeTypes = {
  startNode: StartNode,
  transferNode: TransferNode,
  settingsNode: SettingsNode,
  conversationNode: ConversationNode,
  endNode: EndNode,
  knowledgeBaseNode: KnowledgeBaseNode,
  messagesNode: MessagesNode,
  makeNode: MakeNode, // Added
  ghlNode: GHLNode, // Added
};

const sidebars = {
  node: NodeSidebar,
  test: TestAgentSidebar,
  startNode: StartSidebar,
  transferNode: TransferSidebar,
  settingsNode: SettingsSidebar,
  conversationNode: ConversationSidebar,
  endNode: EndSidebar,
  knowledgeBaseNode: KnowledgeBaseSidebar,
  messagesNode: MessagesSidebar,
  makeNode: MakeSidebar, // Added
  ghlNode: GHLSidebar, // Added
};

const FlowComponent = () => {
  const navigate = useNavigate();
  const { workspaceId, agentId } = useParams();
  const {
    nodes,
    edges,
    handleAddNode,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onNodeDragStop,
    agentName,
    handleAgentNameChange,
    selectedNode,
    setSelectedNode,
  } = useNodeContext();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef();
  const { getViewport } = useReactFlow();

  const handleSidebarOpen = (type) => {
    setIsSidebarOpen(type);
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  const addNode = (newNode) => {
    handleAddNode(newNode);
    handleSidebarClose();
  };

  const handleChange = (e) => {
    handleAgentNameChange(e.target.value);
  };

  const handleCanvasClick = (event) => {
    if (
      !event.target.closest(".react-flow__node") &&
      (!sidebarRef.current || !sidebarRef.current.contains(event.target))
    ) {
      setSelectedNode(null);
      setIsSidebarOpen(false);
    }
  };

  const handleNodeClick = (event, node) => {
    setSelectedNode(node);
    handleSidebarOpen(node.type);
  };

  const SidebarComponent = sidebars[isSidebarOpen];
  const proOptions = { hideAttribution: true };

  // Get the current viewport transformation
  const { x, y, zoom } = getViewport();

  // Calculate the center of the viewport in the canvas coordinates
  const viewportCenter = {
    x: (window.innerWidth / 2.5 - x) / zoom,
    y: (window.innerHeight / 3 - y) / zoom,
  };

  return (
    <Flex height="calc(100vh - 81px)" direction="column" position="relative">
      <Flex
        flex="1"
        direction="row"
        position="relative"
        width="100%"
        overflow="hidden"
      >
        <ReactFlow
          nodes={nodes.map((node) => ({
            ...node,
            style: {
              ...node.style,
              border:
                node.id === (selectedNode && selectedNode.id)
                  ? "0px solid #3182CE"
                  : "0px solid #CBD5E0",
            },
          }))}
          edges={edges}
          proOptions={proOptions}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeDragStop={onNodeDragStop}
          onPaneClick={handleCanvasClick}
          onNodeClick={handleNodeClick}
          style={{ width: "100%", height: "100%" }}
          fitView
          selectNodesOnDrag
          selectionKeyCode="Shift"
          nodeTypes={nodeTypes}
        >
          <Background
            color="#dae2e2"
            size={3.5}
            style={{ backgroundColor: "#f4f6f6" }}
          />
          <Controls />
        </ReactFlow>

        <HStack mb={2} position="absolute" top={4} left={4}>
          <Tooltip label="Go Back" placement="right" hasArrow>
            <IconButton
              icon={<ChevronLeftIcon boxSize={8} />}
              variant="ghost"
              onClick={() =>
                navigate(`/dashboard/${workspaceId}/voice-agents/`)
              }
              aria-label="Go Back"
              bg="white"
              borderColor="gray.200"
              borderWidth="2px"
              color="gray.500"
              size="lg"
            />
          </Tooltip>
          <Tooltip label="Edit Agent" placement="right" hasArrow>
            <IconButton
              icon={<FaRegUserCircle boxSize={8} />}
              variant="ghost"
              onClick={() =>
                navigate(
                  `/dashboard/${workspaceId}/voice-agent/agents/${agentId}/edit`
                )
              }
              aria-label="Edit Agent"
              bg="white"
              borderColor="gray.200"
              borderWidth="2px"
              color="gray.500"
              size="lg"
            />
          </Tooltip>
          <Tooltip label="Agent Details" placement="right" hasArrow>
            <IconButton
              icon={<FaEye boxSize={8} />}
              variant="ghost"
              onClick={() =>
                navigate(
                  `/dashboard/${workspaceId}/voice-agent/agents/${agentId}`
                )
              }
              aria-label="Agent Details"
              bg="white"
              borderColor="gray.200"
              borderWidth="2px"
              color="gray.500"
              size="lg"
            />
          </Tooltip>
          <Tooltip label="Edit Form" placement="right" hasArrow>
            <IconButton
              icon={<FaEdit boxSize={8} />}
              variant="ghost"
              onClick={() =>
                navigate(
                  `/dashboard/${workspaceId}/voice-agent/agents/${agentId}/form-edit`
                )
              }
              aria-label="Edit Form"
              bg="white"
              borderColor="gray.200"
              borderWidth="2px"
              color="gray.500"
              size="lg"
            />
          </Tooltip>
          <Tooltip label="View Submissions" placement="right" hasArrow>
            <IconButton
              icon={<FaEnvelopeOpenText boxSize={8} />}
              variant="ghost"
              onClick={() =>
                navigate(
                  `/dashboard/${workspaceId}/voice-agent/agents/${agentId}/submissions`
                )
              }
              aria-label="Submissions"
              bg="white"
              borderColor="gray.200"
              borderWidth="2px"
              color="gray.500"
              size="lg"
            />
          </Tooltip>
          <Tooltip label="Agent Name" placement="right" hasArrow>
            <Input
              value={agentName}
              onChange={handleChange}
              placeholder="Enter agent name"
              borderColor="gray.200"
              borderWidth="2px"
              bg="white"
              borderRadius={15}
              size="lg"
            />
          </Tooltip>
        </HStack>
        <Box
          display="flex"
          flexDirection="column"
          borderColor="gray.200"
          borderWidth="2px"
          borderRadius={15}
          bg="white"
          p={2}
          width="80px"
          position="absolute"
          top={20}
          left={4}
        >
          <Tooltip label="Add Node" placement="right" hasArrow>
            <IconButton
              icon={<FaPlus size="24px" />}
              onClick={() => handleSidebarOpen("node")}
              borderColor="gray.200"
              borderWidth="0px"
              width="60px"
              height="60px"
              bg="white"
              mb={2}
              color="gray.500"
            />
          </Tooltip>
          <Tooltip label="Test Agent" placement="right" hasArrow>
            <IconButton
              icon={<FaFlask size="24px" />}
              onClick={() => handleSidebarOpen("test")}
              borderColor="gray.200"
              borderWidth="0px"
              width="60px"
              height="60px"
              bg="white"
              color="gray.500"
            />
          </Tooltip>
        </Box>
        <Box
          position="absolute"
          top="0"
          right="0"
          height="100%"
          width="500px"
          zIndex="10"
          transition="transform 0.3s ease-in-out"
          transform={isSidebarOpen ? "translateX(0)" : "translateX(100%)"}
        >
          {SidebarComponent && (
            <SidebarComponent
              ref={sidebarRef}
              isOpen={!!isSidebarOpen}
              onClose={handleSidebarClose}
              addNode={addNode}
              viewportCenter={viewportCenter}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

const Flow = () => (
  <NodeProvider>
    <ReactFlowProvider>
      <FlowComponent />
    </ReactFlowProvider>
  </NodeProvider>
);

export default Flow;
