// components/Contacts.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Flex,
  Input,
  VStack,
  InputGroup,
  InputLeftElement,
  Icon,
  Spinner,
  Button,
  useToast,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useAuth } from '../AuthContext';
import getUserWorkspaces from '../utils/getUserWorkspaces';
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  setDoc,
} from 'firebase/firestore';
import { useNavigate, Outlet } from 'react-router-dom';
import { FiUser } from 'react-icons/fi';
import axios from 'axios';

const Contacts = () => {
  const { currentUser, currentWorkspace } = useAuth();
  const [contacts, setContacts] = useState([]);
  const [workspaceId, setWorkspaceId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchContacts = async () => {
      if (currentUser) {
        try {
          const workspaces = await getUserWorkspaces(currentUser.uid);
          if (workspaces.length > 0) {
            const workspaceId = workspaces[0];
            setWorkspaceId(workspaceId);
            console.log('Workspace ID:', workspaceId);

            const db = getFirestore();
            const contactsQuery = collection(db, 'workspaces', workspaceId, 'contacts');
            const querySnapshot = await getDocs(contactsQuery);
            const contactsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setContacts(contactsData);
            setFilteredContacts(contactsData);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching contacts:', error);
          setLoading(false);
        }
      }
    };

    fetchContacts();
  }, [currentUser]);

  useEffect(() => {
    const filterContacts = () => {
      if (!searchTerm) {
        setFilteredContacts(contacts);
      } else {
        const lowercasedTerm = searchTerm.toLowerCase();
        const filtered = contacts.filter(contact =>
          (contact.firstName && contact.firstName.toLowerCase().includes(lowercasedTerm)) ||
          (contact.middleName && contact.middleName.toLowerCase().includes(lowercasedTerm)) ||
          (contact.lastName && contact.lastName.toLowerCase().includes(lowercasedTerm)) ||
          (contact.phoneNumber && contact.phoneNumber.toLowerCase().includes(lowercasedTerm))
        );
        setFilteredContacts(filtered);
      }
    };

    filterContacts();
  }, [searchTerm, contacts]);

  const handleContactClick = (contactId) => {
    navigate(`/dashboard/${workspaceId}/contacts/${contactId}`);
  };

  const handleSyncContacts = async () => {
    if (!currentWorkspace || !currentWorkspace.ghl) {
      console.error('GHL integration not set up for this workspace');
      toast({
        title: 'GHL integration not set up for this workspace',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const { access_token, locationId } = currentWorkspace.ghl;

    if (!access_token || !locationId) {
      console.error('GHL access token or locationId missing');
      toast({
        title: 'GHL access token or locationId missing',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      // Make the GET request to GHL API to fetch contacts
      const response = await axios.get('https://services.leadconnectorhq.com/contacts/', {
        params: {
          locationId: locationId,
          limit: 100, // Max 100
        },
        headers: {
          'Authorization': `Bearer ${access_token}`,
          'Version': '2021-07-28',
          'Accept': 'application/json',
        },
      });

      const ghlContacts = response.data.contacts;

      // Fetch existing contacts from Firestore to check for duplicates
      const db = getFirestore();
      const contactsRef = collection(db, 'workspaces', workspaceId, 'contacts');
      const existingContactsSnapshot = await getDocs(contactsRef);
      const existingContactsMap = {};

      existingContactsSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.email) {
          existingContactsMap[data.email] = doc.id;
        }
        if (data.phoneNumber) {
          existingContactsMap[data.phoneNumber] = doc.id;
        }
        if (data.ghlContactId) {
          existingContactsMap[data.ghlContactId] = doc.id;
        }
      });

      let newContactsCount = 0;

      for (const ghlContact of ghlContacts) {
        const contactEmail = ghlContact.email;
        const contactPhone = ghlContact.phone;
        const ghlContactId = ghlContact.id;

        let exists = false;
        if (ghlContactId && existingContactsMap[ghlContactId]) {
          exists = true;
        }
        if (contactEmail && existingContactsMap[contactEmail]) {
          exists = true;
        }
        if (contactPhone && existingContactsMap[contactPhone]) {
          exists = true;
        }

        if (!exists) {
          // Add contact to Firestore
          const newContactRef = doc(collection(db, 'workspaces', workspaceId, 'contacts'));
          await setDoc(newContactRef, {
            firstName: ghlContact.firstName || '',
            lastName: ghlContact.lastName || '',
            email: contactEmail || '',
            phoneNumber: contactPhone || '',
            createdAt: new Date(),
            ghlContactId: ghlContactId,
            // Map other fields as needed
          });
          newContactsCount++;
        }
      }

      // Fetch the updated list of contacts
      const updatedContactsSnapshot = await getDocs(contactsRef);
      const updatedContactsData = updatedContactsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setContacts(updatedContactsData);
      setFilteredContacts(updatedContactsData);

      console.log(`Synced ${newContactsCount} new contacts.`);
      toast({
        title: `Synced ${newContactsCount} new contacts.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

    } catch (error) {
      console.error('Error syncing contacts:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
      }
      toast({
        title: 'Error syncing contacts. Check console for details.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box display="flex" height="100%">
      <VStack align="stretch" width="400px" height="100%" p={4} borderRight="1px" borderRightColor="gray.200">
        <Flex align="center" justifyContent="space-between">
          <Flex align="center">
            <FiUser size={30} style={{ marginRight: '12px' }} />
            <Heading as="h2" size="xl">
              Contacts
            </Heading>
          </Flex>
          {/* Conditionally render the Sync Contacts button */}
          {currentWorkspace && currentWorkspace.ghl && currentWorkspace.ghl.access_token && (
            <Button onClick={handleSyncContacts} colorScheme="blue">
              Sync Contacts
            </Button>
          )}
        </Flex>
        <Text fontSize="xl" fontWeight="bold" mb={2}>
          {filteredContacts.length} Contacts
        </Text>
        <InputGroup size="lg">
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} color="gray.400" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by name or number"
            bg="white"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
        <Box
          height="calc(100vh - 266px)"
          overflowY="scroll"
          css={{
            '&::-webkit-scrollbar': {
              width: '0px',
            },
            '&::-webkit-scrollbar-track': {
              width: '0px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'transparent',
            },
          }}
        >
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <Spinner />
            </Box>
          ) : (
            <VStack spacing={2} align="stretch">
              {filteredContacts.length > 0 ? (
                filteredContacts.map((contact) => (
                  <Box
                    key={contact.id}
                    p={2}
                    borderWidth="1px"
                    borderRadius="md"
                    onClick={() => handleContactClick(contact.id)}
                    cursor="pointer"
                    _hover={{ bg: 'gray.100' }}
                  >
                    {contact.firstName || contact.middleName || contact.lastName ? (
                      <>
                        <Text fontWeight="bold">
                          {contact.firstName} {contact.middleName} {contact.lastName}
                        </Text>
                        <Text fontWeight="bold">{contact.phoneNumber}</Text>
                      </>
                    ) : (
                      <Text fontWeight="bold">{contact.phoneNumber}</Text>
                    )}
                    {contact.lastCallAt ? (
                      <Text fontSize="sm">
                        Last Call At: {contact.lastCallAt.toDate().toLocaleString()}
                      </Text>
                    ) : (
                      <Text fontSize="sm">Last Call At: N/A</Text>
                    )}
                  </Box>
                ))
              ) : (
                <Text>No contacts found.</Text>
              )}
            </VStack>
          )}
        </Box>
      </VStack>
      <Box flex="1" p={4}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Contacts;
