import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import {
  Box,
  IconButton,
  Text,
  Flex,
  Icon,
  Spacer,
  Textarea,
  FormLabel,
  Select,
  Switch,
  FormControl,
} from '@chakra-ui/react';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { FaCog, FaPlay, FaPause } from 'react-icons/fa';
import { useNodeContext } from '../NodeContext';

const SettingsSidebar = ({ onClose }) => {
  const { selectedNode, setSelectedNode, nodes, setNodes, saveGraphData, edges, deleteNode } = useNodeContext();
  const [voice, setVoice] = useState('');
  const [agentInstructions, setAgentInstructions] = useState('');
  const [enableRecording, setEnableRecording] = useState(false);
  const [fillerInjection, setFillerInjection] = useState(false);
  const [hipaaEnabled, setHipaaEnabled] = useState(false);
  const [backgroundSound, setBackgroundSound] = useState('office');
  const [language, setLanguage] = useState(''); // New state variable for language
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioSrc, setAudioSrc] = useState('');

  const voiceOptions = [
    { id: 'BRXqZ6YAQpe2chRY9PCd', name: 'Jerry' },
    { id: 'P7x743VjyZEOihNNygQ9', name: 'Dakota' },
    { id: 'OvMWa69uXR9XxHJfNTcC', name: 'Ellie' },
    { id: 'tnSpp4vdxKPjI9w0GnoV', name: 'Hope' },
    { id: 'Bwff1jnzl1s94AEcntUq', name: 'Tanya' },
    { id: '2nl9DUgiNPn0xrb1e8ig', name: 'Suzanne' },
    { id: 'LLEUnU5vlkaEV6dSdkOl', name: 'Kim' },
    { id: 'AiVXo6AkAsMPEX0qNgmP', name: 'Matt' },
    { id: 'yHx9q5iHmtVGKONrqrIf', name: 'Dez' },
    { id: 'c6kFzbpMaJ8UMD5P6l72', name: 'Seth' },
    { id: 'mark', name: 'Mark' },
    { id: '4zVVKJJRwoOAAeUwtCQ1', name: 'Aiden' },
  ];

  const languageOptions = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'zh', name: 'Chinese' },
    { code: 'ja', name: 'Japanese' },
    { code: 'ko', name: 'Korean' },
    { code: 'ru', name: 'Russian' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'it', name: 'Italian' },
    { code: 'hi', name: 'Hindi' },
  ];

  useEffect(() => {
    if (selectedNode && selectedNode.type === 'settingsNode') {
      setVoice(selectedNode.data.voice || '');
      setAgentInstructions(selectedNode.data.agentInstructions || '');
      setEnableRecording(selectedNode.data.enableRecording || false);
      setFillerInjection(selectedNode.data.fillerInjection || false);
      setHipaaEnabled(selectedNode.data.hipaaEnabled || false);
      setBackgroundSound(selectedNode.data.backgroundSound || 'office');
      setLanguage(selectedNode.data.language || ''); // Set language from node data
      if (selectedNode.data.voice) {
        setAudioSrc(`${process.env.PUBLIC_URL}/voices/${selectedNode.data.voice}.mp3`);
      }
    }
  }, [selectedNode]);

  const handleVoiceChange = (event) => {
    const updatedVoiceId = event.target.value;
    setVoice(updatedVoiceId);
    updateNodeData({ voice: updatedVoiceId });
    setAudioSrc(`${process.env.PUBLIC_URL}/voices/${updatedVoiceId}.mp3`);
  };

  const handleAgentInstructionsChange = (event) => {
    const updatedInstructions = event.target.value;
    setAgentInstructions(updatedInstructions);
    updateNodeData({ agentInstructions: updatedInstructions });
  };

  const handleEnableRecordingChange = () => {
    const updatedEnableRecording = !enableRecording;
    setEnableRecording(updatedEnableRecording);
    updateNodeData({ enableRecording: updatedEnableRecording });
  };

  const handleFillerInjectionChange = () => {
    const updatedFillerInjection = !fillerInjection;
    setFillerInjection(updatedFillerInjection);
    updateNodeData({ fillerInjection: updatedFillerInjection });
  };

  const handleHipaaEnabledChange = () => {
    const updatedHipaaEnabled = !hipaaEnabled;
    setHipaaEnabled(updatedHipaaEnabled);
    updateNodeData({ hipaaEnabled: updatedHipaaEnabled });
  };

  const handleBackgroundSoundChange = (event) => {
    const updatedBackgroundSound = event.target.value;
    setBackgroundSound(updatedBackgroundSound);
    updateNodeData({ backgroundSound: updatedBackgroundSound });
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    updateNodeData({ language: selectedLanguage });
  };

  const updateNodeData = (updatedData) => {
    const updatedNodes = nodes.map((node) =>
      node.id === selectedNode.id ? { ...node, data: { ...node.data, ...updatedData } } : node
    );
    setNodes(updatedNodes);
    setSelectedNode({ ...selectedNode, data: { ...selectedNode.data, ...updatedData } });
    saveGraphData(updatedNodes, edges);
  };

  const handleClose = () => {
    setSelectedNode(null);
    onClose();
  };

  const togglePlayPause = () => {
    const audioElement = document.getElementById('voice-sample');
    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.currentTime = 0;
      audioElement.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const audioElement = document.getElementById('voice-sample');
    if (audioElement) {
      const handleAudioEnd = () => {
        setIsPlaying(false);
      };
      audioElement.addEventListener('ended', handleAudioEnd);
      return () => {
        audioElement.removeEventListener('ended', handleAudioEnd);
      };
    }
  }, []);

  const handleDelete = () => {
    deleteNode(selectedNode.id);
    handleClose();
  };

  return (
    <Box
      position="absolute"
      top="0"
      right="0"
      width="500px"
      height="100%"
      backgroundColor="white"
      p={4}
      zIndex={10}
      borderLeft="1px"
      borderLeftColor="gray.200"
    >
      {selectedNode && selectedNode.type === 'settingsNode' && (
        <Box>
          <Flex alignItems="center">
            <Icon as={FaCog} w={6} h={6} color="#787878" />
            <Text fontSize="xl" color="gray.500" ml={2}>
              SETTINGS
            </Text>
            <Spacer />
            <IconButton mr={4} icon={<CloseIcon />} onClick={handleClose} />
            <IconButton icon={<DeleteIcon />} colorScheme="red" onClick={handleDelete} />
          </Flex>
          <Box mt={4}>
            <FormControl mt={4}>
              <FormLabel>Voice</FormLabel>
              <Flex>
                <Select value={voice} onChange={handleVoiceChange} flex="1">
                  <option value="">Select Voice</option>
                  {voiceOptions.map((voiceOption) => (
                    <option key={voiceOption.id} value={voiceOption.id}>
                      {voiceOption.name}
                    </option>
                  ))}
                </Select>
                <IconButton
                  icon={isPlaying ? <FaPause /> : <FaPlay />}
                  colorScheme={isPlaying ? 'red' : 'green'}
                  onClick={togglePlayPause}
                  ml={2}
                  isDisabled={!audioSrc}
                />
              </Flex>
              <ReactAudioPlayer
                src={audioSrc}
                id="voice-sample"
                style={{ display: 'none' }}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Agent Instructions</FormLabel>
              <Textarea
                placeholder="Describe how the agent should act"
                value={agentInstructions}
                onChange={handleAgentInstructionsChange}
                height="300px"
              />
            </FormControl>
            <FormControl display="flex" alignItems="center" mt={4}>
              <FormLabel htmlFor="filler-injection" mb="0">
                Filler Injection (Um, Like)
              </FormLabel>
              <Switch id="filler-injection" isChecked={fillerInjection} onChange={handleFillerInjectionChange} />
            </FormControl>
            <FormControl display="flex" alignItems="center" mt={4}>
              <FormLabel htmlFor="hipaa-enabled" mb="0">
                HIPAA Enabled
              </FormLabel>
              <Switch id="hipaa-enabled" isChecked={hipaaEnabled} onChange={handleHipaaEnabledChange} />
            </FormControl>
            <FormControl display="flex" alignItems="center" mt={4}>
              <FormLabel htmlFor="enable-recording" mb="0">
                Enable Recording
              </FormLabel>
              <Switch id="enable-recording" isChecked={enableRecording} onChange={handleEnableRecordingChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Background Sound</FormLabel>
              <Select value={backgroundSound} onChange={handleBackgroundSoundChange}>
                <option value="office">Office</option>
                <option value="off">Off</option>
              </Select>
            </FormControl>
            {/* New Language Selection Dropdown */}
            <FormControl mt={4}>
              <FormLabel>Language</FormLabel>
              <Select value={language} onChange={handleLanguageChange}>
                <option value="">Select Language</option>
                {languageOptions.map((langOption) => (
                  <option key={langOption.code} value={langOption.code}>
                    {langOption.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SettingsSidebar;
