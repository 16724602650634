// components/API.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Button,
  Input,
  VStack,
  HStack,
  Text,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { CopyIcon, DeleteIcon, ViewIcon, ViewOffIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  addDoc,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';

const API = () => {
  const { workspaceId } = useParams();
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showApiKeys, setShowApiKeys] = useState({});
  const db = getFirestore();
  const toast = useToast();

  useEffect(() => {
    const apiKeysRef = collection(db, 'apiKeys');
    const q = query(apiKeysRef, where('workspaceId', '==', workspaceId));

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const keys = [];
        querySnapshot.forEach((doc) => {
          keys.push({ id: doc.id, ...doc.data() });
        });
        setApiKeys(keys);
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching API keys:', error);
        setLoading(false);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [db, workspaceId]);

  const generateApiKey = () => {
    // Generate a new API key using nanoid
    const newApiKey = nanoid(32);
    return newApiKey;
  };

  const handleAddApiKey = async () => {
    const newApiKey = generateApiKey();

    try {
      const apiKeysRef = collection(db, 'apiKeys');
      const newApiKeyDoc = {
        key: newApiKey,
        workspaceId: workspaceId,
        createdAt: new Date(),
      };
      await addDoc(apiKeysRef, newApiKeyDoc);
      // The onSnapshot listener will automatically update the state
      toast({
        title: 'API Key Created',
        description: 'A new API key has been generated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding API key:', error);
      toast({
        title: 'Error',
        description: 'There was an error creating the API key.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteApiKey = async (keyId) => {
    try {
      const apiKeyDocRef = doc(db, 'apiKeys', keyId);
      await deleteDoc(apiKeyDocRef);
      // The onSnapshot listener will automatically update the state
      toast({
        title: 'API Key Deleted',
        description: 'The API key has been deleted.',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting API key:', error);
      toast({
        title: 'Error',
        description: 'There was an error deleting the API key.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCopyApiKey = (apiKey) => {
    navigator.clipboard.writeText(apiKey);
    toast({
      title: 'API Key Copied',
      description: 'The API key has been copied to your clipboard.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const toggleShowApiKey = (id) => {
    setShowApiKeys((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box maxW="600px">
      <Heading mb={4}>API Keys</Heading>
      <HStack spacing={4} mb={4}>
        <Button colorScheme="blue" onClick={handleAddApiKey}>
          Generate New API Key
        </Button>
        <Button
          as="a"
          href="https://docs.clientbase.so/introduction"
          target="_blank"
          rel="noopener noreferrer"
          rightIcon={<ExternalLinkIcon />}
        >
          See the API Docs
        </Button>
      </HStack>
      <VStack spacing={4} align="stretch">
        {apiKeys.map((apiKeyObj) => (
          <HStack key={apiKeyObj.id} spacing={2}>
            <Input
              type={showApiKeys[apiKeyObj.id] ? 'text' : 'password'}
              value={apiKeyObj.key}
              isReadOnly
            />
            <IconButton
              icon={showApiKeys[apiKeyObj.id] ? <ViewOffIcon /> : <ViewIcon />}
              onClick={() => toggleShowApiKey(apiKeyObj.id)}
              aria-label="Toggle API Key Visibility"
              borderWidth="1px"
            />
            <IconButton
              icon={<CopyIcon />}
              onClick={() => handleCopyApiKey(apiKeyObj.key)}
              aria-label="Copy API Key"
              borderWidth="1px"
            />
            <IconButton
              icon={<DeleteIcon />}
              onClick={() => handleDeleteApiKey(apiKeyObj.id)}
              aria-label="Delete API Key"
              colorScheme="red"
            />
          </HStack>
        ))}
        {apiKeys.length === 0 && (
          <Text>No API keys have been generated yet.</Text>
        )}
      </VStack>
    </Box>
  );
};

export default API;
