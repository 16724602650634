// src/components/OAuth.js

import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Flex, Heading, Text } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from './AuthContext';

function OAuth() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { currentWorkspace } = useAuth(); // Get currentWorkspace from AuthContext

  const exchangeCodeForToken = useCallback(
    async (code) => {
      console.log('Exchanging code for token through backend...');
      try {
        // Make a request to the backend endpoint
        const response = await axios.post(
          'https://api.clientbase.dev/oauth/token',
          {
            code: code,
            workspaceId: currentWorkspace.id,
          }
        );

        if (response.data.success) {
          setLoading(false);
          console.log('Authentication successful.');
          // Navigate to the current workspace's dashboard
          navigate(`/dashboard/${currentWorkspace.id}`);
        } else {
          setError('Authentication failed.');
          setLoading(false);
        }
      } catch (err) {
        console.error('Error during authentication:', err);
        if (err.response) {
          console.error('Response data:', err.response.data);
          setError(
            `Authentication error: ${err.response.data.error || 'Unknown error'}`
          );
        } else if (err.request) {
          console.error('No response received:', err.request);
          setError(
            'No response received from the server. Please check your internet connection and try again.'
          );
        } else {
          console.error('Error setting up request:', err.message);
          setError('An unexpected error occurred. Please try again later.');
        }
        setLoading(false);
      }
    },
    [currentWorkspace, navigate]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');

    console.log('OAuth component mounted. Authorization code:', code);

    if (code && currentWorkspace) {
      exchangeCodeForToken(code);
    } else if (!code) {
      console.log('No authorization code received');
      setLoading(false);
      setError('No authorization code received');
    } else if (!currentWorkspace) {
      console.log('No current workspace available');
      setLoading(false);
      setError('No current workspace available');
    }
  }, [location.search, exchangeCodeForToken, currentWorkspace]);

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Heading>Authenticating...</Heading>
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Text color="red.500">{error}</Text>
      </Flex>
    );
  }

  return null; // This component doesn't render anything if successful, it just redirects
}

export default OAuth;
