import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { Handle, Position } from 'reactflow';
import { useNodeContext } from '../NodeContext';
import ghlImage from '../../../../../assets/ghl.png';

const GHLNode = ({ id, data }) => {
  const { selectedNode, setSelectedNode } = useNodeContext();

  const handleClick = () => {
    setSelectedNode({ id, type: 'ghlNode', label: data.label });
  };

  return (
    <Box
      onClick={handleClick}
      style={{
        backgroundColor: 'white',
        padding: '16px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
        color: '#222222',
        border: `2px solid ${
          selectedNode && selectedNode.id === id ? '#3182CE' : '#CBD5E0'
        }`,
        borderRadius: '10px',
        position: 'relative',
      }}
    >
      <Box display="flex" alignItems="center">
        <Image src={ghlImage} w={6} h={6} />
        <span style={{ marginLeft: '8px' }}>GHL Node</span>
      </Box>
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: '#CBD5E0',
          width: 10,
          height: 10,
          borderRadius: '50%',
          right: '-6px',
        }}
      />
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: '#CBD5E0',
          width: 10,
          height: 10,
          borderRadius: '50%',
          left: '-6px',
        }}
      />
    </Box>
  );
};

export default GHLNode;
