// components/AgentContacts.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  Spinner,
  Heading,
  Flex,
  useToast,
  CircularProgress,
} from '@chakra-ui/react';
import { collection, getFirestore, getDocs } from 'firebase/firestore';

const AgentContacts = ({ workspaceId, agent }) => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [callingContactIds, setCallingContactIds] = useState({});
  const [isCallingAll, setIsCallingAll] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const db = getFirestore();
        const contactsCollectionRef = collection(
          db,
          `workspaces/${workspaceId}/contacts`
        );
        const querySnapshot = await getDocs(contactsCollectionRef);
        const contactsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setContacts(contactsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching contacts: ', error);
        setLoading(false);
      }
    };

    fetchContacts();
  }, [workspaceId]);

  const handleCallClick = async (contact) => {
    setCallingContactIds((prevState) => ({
      ...prevState,
      [contact.id]: true,
    }));
    try {
      // Prepare the payload
      const payload = {
        workspaceId,
        agentId: agent.id,
        contactId: contact.id,
      };

      // Make the API call to initiate the call
      const response = await fetch('https://api.clientbase.dev/send-call', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to initiate call');
      }

      const result = await response.json();
      toast({
        title: 'Call initiated',
        description: `Call has been initiated with ${
          contact.firstName || 'Contact'
        }.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      console.log('Call initiated:', result);
    } catch (error) {
      console.error('Error initiating call:', error);
      toast({
        title: 'Error',
        description: 'There was an error initiating the call.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setCallingContactIds((prevState) => ({
        ...prevState,
        [contact.id]: false,
      }));
    }
  };

  const handleCallAllClick = async () => {
    setIsCallingAll(true);
    try {
      const contactIds = contacts.map((contact) => contact.id);

      // Prepare the payload
      const payload = {
        workspaceId,
        agentId: agent.id,
        contactIds, // Array of contact IDs
      };

      // Make the API call to initiate the calls
      const response = await fetch('https://api.clientbase.dev/send-call', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to initiate calls');
      }

      const result = await response.json();
      toast({
        title: 'Calls initiated',
        description: 'Calls have been initiated with all contacts.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      console.log('Calls initiated:', result);
    } catch (error) {
      console.error('Error initiating calls:', error);
      toast({
        title: 'Error',
        description: 'There was an error initiating calls with all contacts.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsCallingAll(false);
    }
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (contacts.length === 0) {
    return <Text>No contacts available.</Text>;
  }

  return (
    <Box p={4} w="100%" h="100%">
      <Flex alignItems="center" justifyContent="space-between" mb={4}>
        <Heading size="md">Contacts</Heading>
        <Button
          colorScheme="blue"
          size="sm"
          onClick={handleCallAllClick}
          isDisabled={isCallingAll}
          leftIcon={
            isCallingAll && (
              <Spinner size="xs" color="white" />
            )
          }
        >
          {isCallingAll ? 'Calling...' : 'Call All'}
        </Button>
      </Flex>
      <Box overflowY="auto" maxHeight="calc(100vh - 200px)">
        <VStack spacing={2} align="stretch">
          {contacts.map((contact) => (
            <Flex
              key={contact.id}
              borderWidth="1px"
              borderRadius="md"
              p={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <VStack align="start" spacing={0}>
                <Text fontSize="lg" fontWeight="bold">
                  {contact.firstName || ''} {contact.lastName || ''}
                </Text>
                <Text color="gray.600">{contact.phoneNumber}</Text>
              </VStack>
              <Button
                size="sm"
                colorScheme="blue"
                onClick={() => handleCallClick(contact)}
                isDisabled={callingContactIds[contact.id]}
                leftIcon={
                  callingContactIds[contact.id] && (
                    <Spinner size="xs" color="white" />
                  )
                }
              >
                {callingContactIds[contact.id]
                  ? 'Calling...'
                  : 'Call with this agent'}
              </Button>
            </Flex>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

export default AgentContacts;
