// src/contexts/AuthContext.js
import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../Firebase'; // Import Firestore database
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore methods

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [workspaces, setWorkspaces] = useState([]); // State for the list of workspaces
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const navigateToLogin = useCallback(() => {
    if (!['/login', '/signup', '/auth'].includes(window.location.pathname)) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      setCurrentUser(user);

      if (user) {
        // Fetch user data from Firestore
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            const workspaceIds = userData.workspaces;

            if (workspaceIds && workspaceIds.length > 0) {
              // Fetch workspace data
              const workspaceDataPromises = workspaceIds.map(async (workspaceId) => {
                const workspaceDoc = await getDoc(doc(db, 'workspaces', workspaceId));
                return { id: workspaceId, ...workspaceDoc.data() };
              });
              const workspacesData = await Promise.all(workspaceDataPromises);
              setWorkspaces(workspacesData);

              // Set currentWorkspace to the first one if not already set
              if (!currentWorkspace) {
                setCurrentWorkspace(workspacesData[0]);
              }
            } else {
              setCurrentWorkspace(null);
              setWorkspaces([]);
            }
          } else {
            console.error('User document does not exist!');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setCurrentWorkspace(null);
        setWorkspaces([]);
        navigateToLogin();
      }

      setLoading(false);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only on mount

  // Function to set the current workspace
  const setWorkspace = (workspace) => {
    setCurrentWorkspace(workspace);
  };

  const value = {
    currentUser,
    currentWorkspace,
    workspaces,      // Provide list of workspaces
    setWorkspace,    // Provide function to set the current workspace
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
