// components/Settings.js
import React from 'react';
import { VStack, Heading, Button, Box, Flex } from '@chakra-ui/react';
import { Outlet, Link, useParams } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';

const Settings = () => {
  const { workspaceId } = useParams();

  return (
    <Box display="flex">
      <VStack
        as="nav"
        bg="gray.100"
        w="300px"
        p={4}
        spacing={4}
        align="start"
        position="sticky"
        top="0"
        height="calc(100vh - 81px)"
      >
        <Flex alignItems="center">
          <FiSettings size={30} style={{ marginRight: '12px' }} />
          <Heading as="h2" size="xl">
            Settings
          </Heading>
        </Flex>
        <Button as={Link} to={`/dashboard/${workspaceId}/settings/general`} variant="link">General</Button>
        <Button as={Link} to={`/dashboard/${workspaceId}/settings/members`} variant="link">Members</Button>
        <Button as={Link} to={`/dashboard/${workspaceId}/settings/plan-billing`} variant="link">Plan & Billing</Button>
        <Button as={Link} to={`/dashboard/${workspaceId}/settings/phone-numbers`} variant="link">Phone Numbers</Button>
        <Button as={Link} to={`/dashboard/${workspaceId}/settings/api`} variant="link">API</Button>
      </VStack>
      <Box flex="1" p={4}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Settings;
