import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Input,
  Textarea,
  Stack,
  FormControl,
  FormLabel,
  Flex,
  VStack,
  HStack,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { FiUser, FiPhone, FiMessageSquare, FiPlus, FiTrash2, FiEdit2 } from "react-icons/fi";
import ContactMessages from "./ContactMessages";

const ContactDetail = () => {
  const { workspaceId, contactId } = useParams();
  const navigate = useNavigate();
  const [contact, setContact] = useState(null);
  const [callLogs, setCallLogs] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [editingHeader, setEditingHeader] = useState(null);

  useEffect(() => {
    const fetchContactAndCallLogs = async () => {
      const db = getFirestore();
      const contactDoc = await getDoc(
        doc(db, "workspaces", workspaceId, "contacts", contactId)
      );
      if (contactDoc.exists()) {
        const contactData = contactDoc.data();
        setContact(contactData);
        setCustomFields(contactData.customFields || []);

        // Fetch call logs
        const callLogsRef = collection(db, `workspaces/${workspaceId}/logs`);
        const q = query(
          callLogsRef,
          where("message.call.customer.number", "==", contactData.phoneNumber)
        );
        const querySnapshot = await getDocs(q);
        const logs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCallLogs(logs);
      } else {
        console.error("No such contact!");
      }
    };

    fetchContactAndCallLogs();
  }, [workspaceId, contactId]);

  const handleInputChange = async (e, index) => {
    const { name, value } = e.target;
    const db = getFirestore();
    const contactRef = doc(
      db,
      "workspaces",
      workspaceId,
      "contacts",
      contactId
    );

    if (index !== undefined) {
      // Update custom field
      const updatedCustomFields = [...customFields];
      updatedCustomFields[index] = { ...updatedCustomFields[index], value };
      setCustomFields(updatedCustomFields);
      await updateDoc(contactRef, { customFields: updatedCustomFields });
    } else {
      // Update regular field
      setContact((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      await updateDoc(contactRef, { [name]: value });
    }
  };

  const handleAddCustomField = async () => {
    const newField = { key: `Custom Field ${customFields.length + 1}`, value: '' };
    const updatedCustomFields = [...customFields, newField];
    setCustomFields(updatedCustomFields);

    const db = getFirestore();
    const contactRef = doc(
      db,
      "workspaces",
      workspaceId,
      "contacts",
      contactId
    );
    await updateDoc(contactRef, { customFields: updatedCustomFields });
  };

  const handleHeaderChange = async (e, index) => {
    const newKey = e.target.value;
    const updatedCustomFields = [...customFields];
    updatedCustomFields[index] = { ...updatedCustomFields[index], key: newKey };
    setCustomFields(updatedCustomFields);

    const db = getFirestore();
    const contactRef = doc(
      db,
      "workspaces",
      workspaceId,
      "contacts",
      contactId
    );
    await updateDoc(contactRef, { customFields: updatedCustomFields });
  };

  const handleDeleteCustomField = async (index) => {
    const updatedCustomFields = customFields.filter((_, i) => i !== index);
    setCustomFields(updatedCustomFields);
    setEditingHeader(null);

    const db = getFirestore();
    const contactRef = doc(
      db,
      "workspaces",
      workspaceId,
      "contacts",
      contactId
    );
    await updateDoc(contactRef, { customFields: updatedCustomFields });
  };

  const handleCallLogClick = (logId) => {
    navigate(`/dashboard/${workspaceId}/call-logs/${logId}`);
  };

  if (!contact) {
    return <Text>Loading...</Text>;
  }

  return (
    <Flex p={4}>
      <Box flex="1">
        <Stack borderWidth="1px" p={4} borderRadius="md" spacing={3}>
          <Flex justifyContent="space-between" alignItems="center">
            <HStack spacing={2}>
              <FiUser size={24} />
              <Heading>Contact Details</Heading>
            </HStack>
            <IconButton
              icon={<FiPlus />}
              onClick={handleAddCustomField}
              aria-label="Add custom field"
              borderWidth="1px"
              bg="white"
            />
          </Flex>
          <Text>
            Created At: {contact.createdAt ? contact.createdAt.toDate().toLocaleString() : 'N/A'}
          </Text>
          <Text>
            Last Call At: {contact.lastCallAt ? contact.lastCallAt.toDate().toLocaleString() : 'N/A'}
          </Text>
          <Flex>
            <Box flex="1" mr={2}>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  placeholder="First Name"
                  value={contact.firstName || ""}
                  name="firstName"
                  onChange={handleInputChange}
                  size="lg"
                  fontWeight="bold"
                />
              </FormControl>
            </Box>
            <Box flex="1" mx={2}>
              <FormControl>
                <FormLabel>Middle Name</FormLabel>
                <Input
                  placeholder="Middle Name"
                  value={contact.middleName || ""}
                  name="middleName"
                  onChange={handleInputChange}
                  size="lg"
                  fontWeight="bold"
                />
              </FormControl>
            </Box>
            <Box flex="1" ml={2}>
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  placeholder="Last Name"
                  value={contact.lastName || ""}
                  name="lastName"
                  onChange={handleInputChange}
                  size="lg"
                  fontWeight="bold"
                />
              </FormControl>
            </Box>
          </Flex>
          <Flex>
            <Box flex="1" mr={2}>
              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  placeholder="Phone Number"
                  value={contact.phoneNumber || ""}
                  name="phoneNumber"
                  onChange={handleInputChange}
                  size="lg"
                  fontWeight="bold"
                />
              </FormControl>
            </Box>
            <Box flex="1" ml={2}>
              <FormControl>
                <FormLabel>Company Name</FormLabel>
                <Input
                  placeholder="Company Name"
                  value={contact.companyName || ""}
                  name="companyName"
                  onChange={handleInputChange}
                  size="lg"
                  fontWeight="bold"
                />
              </FormControl>
            </Box>
          </Flex>

          {customFields.map((field, index) => (
            <FormControl key={index}>
              <Flex alignItems="center" mb={2}>
                {editingHeader === index ? (
                  <Input
                    value={field.key}
                    onChange={(e) => handleHeaderChange(e, index)}
                    onBlur={() => setEditingHeader(null)}
                    size="lg"
                    fontWeight="bold"
                    flex="1"
                    autoFocus
                  />
                ) : (
                  <Text fontWeight="bold" flex="1">{field.key}</Text>
                )}
                <IconButton
                  icon={<FiEdit2 />}
                  onClick={() => setEditingHeader(index)}
                  aria-label="Edit custom field name"
                  size="sm"
                  mr={2}
                />
                <IconButton
                  icon={<FiTrash2 />}
                  onClick={() => handleDeleteCustomField(index)}
                  aria-label="Delete custom field"
                  size="sm"
                  colorScheme="red"
                />
              </Flex>
              <Input
                placeholder={field.key}
                value={field.value}
                onChange={(e) => handleInputChange(e, index)}
                size="lg"
                fontWeight="bold"
              />
            </FormControl>
          ))}

          <FormControl>
            <FormLabel>Additional Information</FormLabel>
            <Textarea
              placeholder="Additional Information"
              value={contact.additionalInfo || ""}
              name="additionalInfo"
              onChange={handleInputChange}
              size="lg"
              fontWeight="bold"
            />
          </FormControl>
        </Stack>
      </Box>

      <Box width="400px" ml={4}>
        <Stack h="500px" mb={4} borderWidth="1px" p={4} borderRadius="md" spacing={3} overflowY="auto">
          <HStack spacing={2} alignItems="center">
            <FiMessageSquare size={24} />
            <Heading>Messages</Heading>
          </HStack>
          <ContactMessages contactId={contactId} workspaceId={workspaceId} />
        </Stack>

        <Box borderWidth="1px" p={4} borderRadius="md">
          <HStack spacing={2} alignItems="center" mb={4}>
            <FiPhone size={24} />
            <Heading size="lg">Call Logs</Heading>
          </HStack>
          <Box
            overflowY="auto"
            height="calc(100vh - 750px)"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "gray.300",
                borderRadius: "24px",
              },
            }}
          >
            <VStack align="stretch" spacing={2}>
              {callLogs.length === 0 ? (
                <Text>No call logs found for this contact</Text>
              ) : (
                callLogs.map((log) => (
                  <Button
                    key={log.id}
                    onClick={() => handleCallLogClick(log.id)}
                    variant="outline"
                    justifyContent="space-between"
                    width="100%"
                    whiteSpace="normal"
                    textAlign="left"
                    py={2}
                  >
                    <Text>
                      {new Date(log.message.call.createdAt).toLocaleString()}
                    </Text>
                  </Button>
                ))
              )}
            </VStack>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default ContactDetail;
